import { Navigate, Outlet, useSearchParams, useLocation } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { useUserContext } from '@common/contexts/UserContext';
import usePushNotification from '@common/hooks/usePushNotification';

import { base64Decrypt } from '@libs/utils/helpers/base64';
import { JaiminhoClient } from '@libs/jaiminho';

import { useEffect } from 'react';
import { unmask } from 'remask';

const PrivateRoute = () => {
  const { isAuthenticated, isLoading, user, signOut } = useUserContext();
  const { track: trackPushNotification } = usePushNotification();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const identify = searchParams.get('identify');
    trackPushNotification(user);

    if (identify && user) {
      const decodedDocument = unmask(base64Decrypt(identify));
      if (user?.document !== decodedDocument) signOut();
    }
  }, [user]);

  if (isLoading) return <LoaderCircular fullPage />;
  if (!isLoading && !isAuthenticated) {
    sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
    return <Navigate to={RouteMap.Root + location.search} replace />;
  }

  return (
    <>
      <Outlet />
      {!window.isNativeApp && (
        <JaiminhoClient
          apiKey={import.meta.env.VITE_PUSH_WEB_API_KEY}
          marketplaceApi={import.meta.env.VITE_PUSH_API_CP}
          applicationServerKey={import.meta.env.VITE_PUSH_WEB_SERVER_KEY}
          options={{
            pushOnTimeout: 15000,
            promptTitle: '',
            promptMessage:
              'Olá, você gostaria de ser avisado sobre novas ofertas?',
            promptButton: 'Sim',
            promptDismiss: 'Não',
            bell: false,
            button: false,
            prompt: true,
          }}
        />
      )}
    </>
  );
};

export default PrivateRoute;
