import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { useEffect } from 'react';
import errorGauge from '@common/assets/illustration/error-gauge.svg';
import errorInfo from '@common/assets/illustration/error-info.svg';
import { RouteMap } from '@common/constants/RouteMap';

interface UseErrorProps {
  eventName: string;
}

export const useError = ({ eventName }: UseErrorProps) => {
  const { elementClickedEvent, elementViewedEvent } = useTracking();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const variants = {
    score: {
      src: errorGauge,
      alt: 'Medidor de score com um ponto de interrogação no centro',
      subtitle:
        'Ocorreu um erro e não conseguimos acessar suas informações de Score',
    },
    dados: {
      src: errorInfo,
      alt: 'Uma folha de documento com um X',
      subtitle: 'Ocorreu um erro e não conseguimos acessar seus dados',
    },
    notFound: {
      src: errorInfo,
      alt: 'Uma folha de documento com um X',
      subtitle:
        'Ocorreu um erro e não conseguimos encontrar a informação que está buscando',
    },
  };

  useEffect(() => {
    elementViewedEvent({
      elementType: 'content',
      location: pathname,
      name: `Error - ${eventName}`,
    });
  }, []);

  function handleGoBack() {
    elementClickedEvent({
      elementType: 'button/text',
      text: 'Voltar',
      name: `Error - ${eventName}`,
      location: pathname,
    });
    Object.keys(window.history.state).length === 0
      ? navigate(RouteMap.Dashboard)
      : window.history.back();
  }

  return {
    variants,
    handleGoBack,
  };
};
