type OneLinkURLResponse = {
  clickURL?: string;
};

const oneLinkURL = 'https://consumidorpositivo.onelink.me/3ote';
const webReferrer = 'referrer_url';
const mediaSource = { keys: ['utm_source'], defaultValue: 'organico' };
const campaign = { keys: ['utm_campaign'] };
const googleClickIdKey = 'af_sub1';
const channel = { keys: ['utm_medium'] };
const utm_term = { paramKey: 'utm_term', keys: ['utm_term'] };
const utm_content = { paramKey: 'utm_content', keys: ['utm_content'] };
const utm_keyword = { paramKey: 'utm_keyword', keys: ['utm_keyword'] };
const utm_product = { paramKey: 'utm_product', keys: ['utm_product'] };
const utm_campaign_type = {
  paramKey: 'utm_campaign_type',
  keys: ['utm_campaign_type'],
};
const identify = { paramKey: 'identify', keys: ['identify'] };
const utm_medium = { paramKey: 'utm_medium', keys: ['utm_medium'] };
const utm_campaign = { paramKey: 'utm_campaign', keys: ['utm_campaign'] };
const utm_source = { paramKey: 'utm_source', keys: ['utm_source'] };
const afSub2 = { keys: ['fbclid'] };
const is_retargeting = { paramKey: 'is_retargeting', defaultValue: 'true' };
const session_id = { paramKey: 'session_id', keys: ['session_id'] };
const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };

// Helper functions
const createTempQRContainer = () => {
  const div = document.createElement('div');
  div.id = `qr-code-${crypto.randomUUID().slice(0, 8)}`;
  document.body.appendChild(div);
  return div;
};

const getQRCodeImage = (container: HTMLElement) => {
  const canvas = container.querySelector('canvas');
  return canvas?.toDataURL('image/png');
};

export const useSmartScript = () => {
  const generateOneLinkURL = (): OneLinkURLResponse => {
    const afParameters = {
      mediaSource,
      campaign,
      googleClickIdKey,
      channel,
      afCustom: [
        utm_term,
        utm_content,
        utm_keyword,
        utm_product,
        utm_campaign_type,
        identify,
        utm_medium,
        utm_campaign,
        utm_source,
        is_retargeting,
        session_id,
        custom_ss_ui,
      ],
      afSub2,
    };

    return window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL,
      webReferrer,
      afParameters,
    });
  };

  const generateOneLinkQRCode = () => {
    // Create temporary QR code container
    const tempDiv = createTempQRContainer();

    // Generate and display QR code
    window.AF_SMART_SCRIPT.displayQrCode(tempDiv.id);

    // Get QR code image and cleanup
    const base64 = getQRCodeImage(tempDiv);
    document.body.removeChild(tempDiv);

    return base64;
  };

  return { generateOneLinkURL, generateOneLinkQRCode };
};
