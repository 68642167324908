import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { useACRedirect } from '@dues/hooks/useACRedirect';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import { ElementEventPayload } from '@common/hooks/useTracking/types';
import { useSmartScript } from '@common/hooks/useSmartScript';

const useFooter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { generateOneLinkURL } = useSmartScript();
  const { elementClickedEvent } = useTracking();
  const { sendProposalStartedAndRedirectToAC } = useACRedirect();
  const { isMobile } = useDeviceSize();
  const { pathname } = useLocation();

  function handleClick(e: React.MouseEvent<HTMLAnchorElement> | undefined) {
    const { tagName, dataset, innerText } = e?.target as HTMLElement;
    const eventPayload = {
      elementType: 'link',
      location: 'footer',
      position: dataset.category,
      text: tagName === 'A' ? innerText : dataset.src,
    } as ElementEventPayload;

    if (innerText === 'Acordo Certo') {
      e?.preventDefault();
      const params =
        '?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=footer-home&utm_content=logged-in';
      sendProposalStartedAndRedirectToAC(params);
    }

    elementClickedEvent(eventPayload);
  }

  const handleAppStoresClick = () => {
    elementClickedEvent({
      elementType: 'button',
      text: `Footer Stores Desktop: Redirect para as lojas`,
      location: pathname,
    });

    setIsModalOpen(true);
  };

  const handleStoreRedirect = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    const { clickURL } = generateOneLinkURL();

    if (isMobile) {
      elementClickedEvent({
        elementType: 'link/image',
        text: `Footer Stores: ${isIOS ? 'Apple Store' : 'Google Play'}`,
        location: pathname,
      });

      window.open(clickURL, '_top');
    } else {
      handleAppStoresClick();
    }
  };

  return {
    handleClick,
    handleStoreRedirect,
    isModalOpen,
    setIsModalOpen,
  };
};

export default useFooter;
