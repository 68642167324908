import classNames from 'classnames';
import { render } from 'storyblok-rich-text-react-renderer';
import {
  Text,
  Button,
  Conditional,
  IconInfo,
} from '@consumidor-positivo/aurora';

import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

import { Error } from '@common/components/Error';
import { Container } from '@common/components/Container';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { PrivateDefaultTemplate } from '@common/Templates/PrivateDefault';

import { RedirectModal } from '@hub/components/RedirectModal';
import { HubTag } from '@hub/components/HubCard/components/HubTag';
import { HubHighlights } from '@hub/components/HubCard/components/HubHighlights';

import { Collapse } from '../../components/Collapse';

import { icons } from './data';
import { useDetails } from './hook';
import './styles.scss';

export const Details = () => {
  const {
    error,
    offer,
    shLoading,
    buttonRef,
    fixedButton,
    showContent,
    handleGoBack,
    handleRedirect,
    redirectModal,
    loadingRedirect,
    productCategory,
  } = useDetails();

  const { isDesktop } = useDeviceSize();

  const fixedActionClass = classNames('hub-details__fixed-action', {
    'hub-details__fixed-action--visible': fixedButton,
  });

  const detailClass = classNames('hub-details', {
    'hub-details--loading': !showContent,
  });

  if (shLoading) {
    return (
      <PrivateDefaultTemplate>
        <Container centralize expandY>
          <LoaderCircular />
        </Container>
      </PrivateDefaultTemplate>
    );
  }

  if (!shLoading && error) {
    return (
      <PrivateDefaultTemplate>
        <Container>
          <Error variant="notFound" />
        </Container>
      </PrivateDefaultTemplate>
    );
  }

  return (
    <PrivateDefaultTemplate>
      {offer && (
        <Container>
          <div className={detailClass}>
            <div className="hub-details__hero">
              <div className="hub-details__hero__image">
                <img
                  src={offer?.content.image_logo.filename}
                  alt={offer?.content.title}
                />
              </div>
              <div className="hub-details__hero__title">
                <Text
                  as="h1"
                  variant="heading-small"
                  variantDesk="heading-large"
                  weight={offer?.generalInfo?.limit ? 'medium' : 'bold'}
                >
                  {offer?.content.title}
                </Text>
                <Conditional
                  condition={!!offer?.generalInfo?.limit}
                  renderIf={
                    <Text
                      as="h4"
                      variant="heading-small"
                      variantDesk="heading-large"
                      weight="bold"
                    >
                      {`Limite de ${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        maximumFractionDigits: 0,
                      }).format(offer.generalInfo.limit || 0)}`}
                    </Text>
                  }
                />
              </div>
              <div className="hub-details__hero__tag">
                <HubTag
                  isPreApproved={
                    offer?.generalInfo?.preApproved ||
                    !!offer?.generalInfo?.limit
                  }
                  featureInformation={offer?.content?.feature_information}
                />
              </div>
              <div className="hub-details__hero__highlights">
                <HubHighlights
                  topics={offer?.content?.highlights_card_iq}
                  hideFeaturedTopics
                />
              </div>
              <div className="hub-details__action" ref={buttonRef}>
                <Button
                  as="button"
                  expand="x"
                  onClick={handleRedirect}
                  disabled={loadingRedirect}
                >
                  Peça já
                </Button>
              </div>
            </div>

            <div className="hub-details__benefits">
              <div className="hub-details__benefits__title">
                <Text
                  as="h2"
                  variant="heading-small"
                  variantDesk="heading-medium"
                  weight="bold"
                >
                  Mais informações sobre o {productCategory}
                </Text>
              </div>

              <div className="hub-details__benefits__collapses">
                {offer?.content?.benefits?.map((benefit, index) => (
                  <Collapse
                    key={`${offer?.generalInfo.slug}-${index}`}
                    title={benefit.title}
                    icon={icons[benefit.icon as keyof typeof icons]}
                    startOpened={index === 0 && isDesktop}
                  >
                    <div className="hub-details__benefits__content">
                      {render(benefit.desc)}
                    </div>
                  </Collapse>
                ))}
              </div>

              <div className="hub-details__alert">
                <IconInfo />
                <Text as="span" variant="body-medium" variantDesk="body-medium">
                  O {productCategory} e os serviços estão sujeitos à aprovação
                  de crédito{' '}
                  {offer?.content?.partner_friendly_name || ' do parceiro'}.
                </Text>
              </div>

              <Button
                as="button"
                expand="x"
                onClick={handleGoBack}
                type="outlined"
                disabled={loadingRedirect}
              >
                Voltar
              </Button>
            </div>
          </div>

          <div className={fixedActionClass}>
            <Button
              as="button"
              expand="x"
              onClick={handleRedirect}
              disabled={loadingRedirect}
            >
              Peça já
            </Button>
          </div>
          <RedirectModal
            isOpen={redirectModal.isOpen}
            offerImage={offer.content.image_logo.filename}
            partnerName={offer?.content?.partner_friendly_name || ' o parceiro'}
          />
        </Container>
      )}
    </PrivateDefaultTemplate>
  );
};
