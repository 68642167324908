import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Conditional, IconChevronRight, Text } from '@consumidor-positivo/aurora';
import { type MatchMakerDecision } from '@hub/types/matchmaker';

import { useTracking } from '@common/hooks/useTracking';
import { CreditCardModal } from '@hub/pages/MatchMaker/components/CreditCardModal';

import helpCircleIcon from '@hub/assets/help-circle.svg';
import './styles.scss';

type DescriptionProps = {
  offer: MatchMakerDecision;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0,
  }).format(value);
};

const userHasFullSpend = (
  monthlyExpenses: number,
  minimumSpendFull: number
) => {
  return minimumSpendFull !== 0 && monthlyExpenses >= minimumSpendFull;
};

const userHasHalfSpend = (monthlyExpenses: number, minimumSpend50: number) => {
  return minimumSpend50 !== 0 && monthlyExpenses >= minimumSpend50;
};

const getAnnuityText = (offer: MatchMakerDecision) => {
  const {
    annuityMonthlyFee,
    monthlyExpenses,
    minimumSpend50,
    minimumSpendFull,
  } = offer;

  if (annuityMonthlyFee === 0) {
    return 'Anuidade grátis';
  }

  if (userHasFullSpend(monthlyExpenses, minimumSpendFull)) {
    return `Anuidade grátis gastando ${formatCurrency(minimumSpendFull)}`;
  }

  if (userHasHalfSpend(monthlyExpenses, minimumSpend50)) {
    return `Anuidade de ${formatCurrency(
      annuityMonthlyFee / 2
    )}/mês gastando ${formatCurrency(minimumSpend50)}`;
  }

  return `Anuidade de ${formatCurrency(annuityMonthlyFee)}/mês`;
};

const isAnnuityFree = (offer: MatchMakerDecision) => {
  const { annuityMonthlyFee, monthlyExpenses, minimumSpendFull } = offer;

  return (
    annuityMonthlyFee === 0 ||
    (minimumSpendFull !== 0 && monthlyExpenses >= minimumSpendFull)
  );
};

export const CreditDescription: React.FC<DescriptionProps> = ({ offer }) => {
  const [activeModal, setActiveModal] = useState<
    'annuity' | 'travel' | 'discount' | null
  >(null);

  const { elementClickedEvent } = useTracking();

  const annuityInfo = offer?.annuityDetails.find(
    (detail) => detail.component === 'info_feature'
  );

  const discountBenefits = offer?.discount.find(
    (detail) => detail.component === 'info_feature'
  );

  const minimumIncome = offer?.featureDescriptions[1];

  const travelBenefits = offer?.loyalty.find(
    (detail) => detail.component === 'travel_benefits'
  );

  const programPoints = offer?.loyalty.find(
    (detail) => detail.component === 'points_program'
  );

  const handleOpenModal = (modal: 'annuity' | 'travel' | 'discount') => {
    const modalName =
      modal === 'annuity' ? 'annuity-info' : `${modal}-benefits-info`;

    elementClickedEvent({
      elementType: 'button',
      location: '/comparador/sucesso',
      name: modalName,
      text: offer.slug,
    });

    setActiveModal(modal);
  };

  const render = (type: 'discount' | 'minimumIncome' | 'travelBenefits' | 'programPoints') => {
    const cashback = offer?.rewardsHighlights.cashback;
    const miles = offer?.rewardsHighlights.miles;

    switch (type) {
      case 'discount':
        if (offer.category === 'cashback') return true;
        if (offer.category === 'cashback-miles') return !!cashback;
        return false;

      case 'minimumIncome':
        if (offer.category === 'cashback') return true
        if (offer.category === 'cashback-miles') return !!cashback && !miles;
        return false;

      case 'travelBenefits':
        if (offer.category === 'miles') return true;
        if (offer.category === 'cashback-miles') return !!miles;
        return false
      case 'programPoints':
        if (offer.category === 'miles') return true;
        if (offer.category === 'cashback-miles') return !cashback && !!miles;
        return false
    }
  }

  return (
    <>
      <div className="credit-description">
        <Conditional
          condition={render('discount')}
          renderIf={
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  Desconto
                </Text>
              </div>
              <div className="credit-description__description">
                {discountBenefits ? (
                  <div onClick={() => handleOpenModal('discount')}>
                    <Text
                      as="p"
                      variant="body-medium"
                      className="credit-description__description--benefits"
                    >
                      <strong>{discountBenefits?.text_button}</strong>
                    </Text>
                    <IconChevronRight size="default" />
                  </div>
                ) : (
                  <Text as="p" variant="body-medium">
                    <strong>Não tem</strong>
                  </Text>
                )}
              </div>
            </div>
          }
        />
        <Conditional
          condition={render('minimumIncome')}
          renderIf={
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  {minimumIncome?.title}
                </Text>
              </div>
              <div className="credit-description__description">
                <Text as="p" variant="body-medium">
                  <strong>{minimumIncome?.description}</strong>
                </Text>
              </div>
            </div>
          }
        />
        <Conditional condition={render('travelBenefits')}
          renderIf={
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  Vantagens para viajar
                </Text>
              </div>
              <div className="credit-description__description">
                {travelBenefits ? (
                  <div onClick={() => handleOpenModal('travel')}>
                    <Text
                      as="p"
                      variant="body-medium"
                      className="credit-description__description--benefits"
                    >
                      <strong>{travelBenefits?.text_button}</strong>
                    </Text>
                    <IconChevronRight size="default" />
                  </div>
                ) : (
                  <Text as="p" variant="body-medium">
                    <strong>Não tem</strong>
                  </Text>
                )}
              </div>
            </div>
          } />
        <Conditional condition={render('programPoints')}
          renderIf={
            <div className="credit-description__details">
              <div className="credit-description__title">
                <Text as="p" variant="body-medium">
                  Programa de pontos
                </Text>
              </div>
              <div className="credit-description__description">
                {programPoints ? (
                  <Text as="p" variant="body-medium">
                    <strong>{programPoints?.name}</strong>
                  </Text>
                ) : (
                  <Text as="p" variant="body-medium">
                    <strong>Não tem</strong>
                  </Text>
                )}
              </div>
            </div>
          }
        />

        <div className="credit-description__annuity">
          <hr className="credit-description__annuity__divider" />
          <Text
            as="p"
            variant="body-small"
            className={`credit-description__annuity__text${isAnnuityFree(offer)
              ? ' credit-description__annuity__text--green'
              : ''
              }`}
          >
            {getAnnuityText(offer)}
          </Text>

          {Boolean(annuityInfo) && (
            <img
              src={helpCircleIcon}
              alt="Icone de circulo com uma interrogação no meio"
              onClick={() => handleOpenModal('annuity')}
            />
          )}
        </div>
      </div>

      {annuityInfo &&
        createPortal(
          <CreditCardModal
            isOpen={activeModal === 'annuity'}
            onClose={() => setActiveModal(null)}
            iconURL={
              (annuityInfo?.icon?.length && annuityInfo?.icon[0].filename) || ''
            }
            title={annuityInfo?.title}
            description={annuityInfo?.description}
          />,
          document.body
        )}

      {travelBenefits &&
        createPortal(
          <CreditCardModal
            isOpen={activeModal === 'travel'}
            onClose={() => setActiveModal(null)}
            iconSize={40}
            iconURL={
              (travelBenefits?.icon?.length &&
                travelBenefits?.icon[0].filename) ||
              ''
            }
            title={travelBenefits?.title}
            description={travelBenefits?.description}
          />,
          document.body
        )}

      {discountBenefits &&
        createPortal(
          <CreditCardModal
            isOpen={activeModal === 'discount'}
            onClose={() => setActiveModal(null)}
            iconSize={24}
            iconURL={
              (discountBenefits?.icon?.length &&
                discountBenefits?.icon[0].filename) ||
              ''
            }
            title={discountBenefits?.title}
            description={discountBenefits?.description}
          />,
          document.body
        )}
    </>
  );
};
