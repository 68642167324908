import untilLoad from '@libs/utils/helpers/untilLoad';
import { setUserId, usePushWebSettingsStore } from '@libs/jaiminho';

const usePushNotification = () => {
  const { isInitialized } = usePushWebSettingsStore((store) => store.settings);

  const track = async (user: any) => {
    const maxTimeoutToLoadScripts = 6000;
    const jaiminhoServiceLoaded = await untilLoad(
      () => isInitialized,
      maxTimeoutToLoadScripts,
      1000
    );

    if (jaiminhoServiceLoaded) {
      setUserId(user?.documento);
    }
  };

  return {
    track,
  };
};

export default usePushNotification;
