import facebookLogo from '../../assets/facebook-logo.svg';
import instagramLogo from '../../assets/instagram-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';

type LinksGroupType = {
  title: string;
  links: {
    url: string;
    text?: string;
    target: HTMLAnchorElement['target'];
    image?: {
      src: string;
      alt: string;
    };
  }[];
};

const utmCrossSell = `?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=footer-home&utm_content=logged-in`;

export const linksGroup: LinksGroupType[] = [
  {
    title: 'Quem Somos',
    links: [
      {
        text: 'Sobre o Consumidor Positivo',
        url: `https://www.consumidorpositivo.com.br/quem-somos`,
        target: '_blank',
      },
      {
        text: 'Termos e Políticas',
        url: `https://www.consumidorpositivo.com.br/termos-e-politica`,
        target: '_blank',
      },
      {
        text: 'Blog',
        url: `https://blog.acordocerto.com.br/${utmCrossSell}`,
        target: '_blank',
      },
      {
        text: 'Evite Fraudes',
        url: `https://www.consumidorpositivo.com.br/evite-fraudes`,
        target: '_blank',
      },
    ],
  },
  {
    title: 'Nossas Soluções',
    links: [
      {
        text: 'Cartões de Crédito',
        url: `https://www.consumidorpositivo.com.br/cartao-de-credito${utmCrossSell}`,
        target: '_blank',
      },
      {
        text: 'Score',
        url: `/`,
        target: '_self',
      },
      {
        text: 'Acordo Certo',
        url: `https://www.acordocerto.com.br/${utmCrossSell}`,
        target: '_blank',
      },
      {
        text: 'Cadastro Positivo',
        url: `https://www.consumidorpositivo.com.br/cadastro-positivo${utmCrossSell}`,
        target: '_blank',
      },
    ],
  },
  {
    title: 'Contato',
    links: [
      {
        text: 'Fale conosco',
        url: `https://www.consumidorpositivo.com.br/formulario`,
        target: '_blank',
      },
      {
        text: 'Central de Ajuda',
        url: `https://ajuda.consumidorpositivo.com.br/hc/pt-br`,
        target: '_blank',
      },
    ],
  },
  {
    title: 'Siga a gente',
    links: [
      {
        image: {
          src: facebookLogo,
          alt: 'Logo do facebook em verde',
        },
        url: 'https://www.facebook.com/consumidorpositivo',
        target: '_blank',
      },
      {
        image: {
          src: instagramLogo,
          alt: 'Logo do instagram em verde',
        },
        url: 'https://www.instagram.com/consumidorpositivo/',
        target: '_blank',
      },
      {
        image: {
          src: youtubeLogo,
          alt: 'Logo do youtube em verde',
        },
        url: 'https://www.youtube.com/@AcordoCertoOficial',
        target: '_blank',
      },
    ],
  },
];
